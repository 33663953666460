import React, { Component, Fragment } from "react";
import Spinner from "../../../../components/Spinner";
import styled, { css } from "styled-components";
import { $, _ } from "js/vendor";
import Thumbnails from "js/core/models/thumbnails";
import { getAverageImageColor } from "js/core/utilities/imageUtilities";
import { ContextMenu } from "js/react/components/UiComponents";
import { THUMBNAIL_SIZES } from "common/constants";
import { themeColors } from "js/react/sharedStyles";
import { Slide } from "js/core/models/slide";

const Frame = styled.div`
`;

const StyledThumbnailGrid = styled.div`
    padding: 2px;
    display: grid;
    grid-column-gap: ${props => props.colGap || 30}px;
    grid-row-gap: ${props => props.rowGap || 30}px;
    grid-auto-rows: max-content;
    
    grid-template-columns: repeat(${props => props.columns || 4}, minmax(0, 1fr));
    grid-template-columns: repeat(${props => props.forcedColumnCount || props.columns - 2 || 1}, minmax(0, 1fr));
    @media(min-width: 0px) {
    }
    @media(min-width: 750px) {
      grid-template-columns: repeat(${props => props.forcedColumnCount || props.columns - 1 || 2}, minmax(0, 1fr));
    }
    @media(min-width: 1200px){
      grid-template-columns: repeat(${props => props.columns || 4}, minmax(0, 1fr));;
    }
    @media(min-width: 1700px){
      grid-template-columns: repeat(${props => props.columns + 1 || 5}, minmax(0, 1fr));;
    }
    label {
        font-size: 14px;
        color: #333;
        letter-spacing: 0px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
    }
`;

export class ThumbnailGrid extends Component {
    handleItemClick = (item, event) => {
        this.props.onItemSelected && this.props.onItemSelected(item);
    }

    handleItemDoubleClick = (item, event) => {
        this.props.onItemDoubleClick && this.props.onItemDoubleClick(item);
    }

    render() {
        let { items, thumbnailClass, renderThumbnail, thumbnailSize, style, columns, colGap, rowGap, firstItem, showThemeThumbnails, selectedItem, thumbnailProps, forcedColumnCount } = this.props;

        let styles = { ...style };
        if (thumbnailSize) {
            styles.gridTemplateColumns = `repeat(auto-fill, ${thumbnailSize}px`;
        }

        return (
            <StyledThumbnailGrid columns={columns} forcedColumnCount={forcedColumnCount} colGap={colGap} rowGap={rowGap} className="thumbnail-grid"
                style={styles}>
                {firstItem ? firstItem : null}
                {
                    items.map(item => {
                        if (!item) {
                            // error: should not happen unless there's a bug, but avoids taking down the entire view
                            return <div style={{ background: "#929292", fontSize: 58, textAlign: "center" }}>?</div>;
                        }
                        if (renderThumbnail) {
                            return <Fragment key={item.id}>{renderThumbnail({ item })}</Fragment>;
                        }
                        return React.createElement(thumbnailClass, {
                            key: item.id,
                            item: item,
                            selected: selectedItem == item,
                            showThemeThumbnails: showThemeThumbnails,
                            onClick: event => this.handleItemClick(item, event),
                            onDoubleClick: event => this.handleItemDoubleClick(item, event),
                            ...thumbnailProps
                        });
                    })
                }
            </StyledThumbnailGrid>
        );
    }
}

export class MultiSelectThumbnailGrid extends Component {
    state = {
        selection: [],
        dragBounds: null
    }

    constructor() {
        super();

        this.gridRef = React.createRef();
    }

    handleGridMouseDown = event => {
        if ($(event.target).hasClass("thumbnail-grid")) {
            this.setState({ selection: [] });
            this.props.onItemSelected && this.props.onItemSelected([]);
        }
    }

    handleThumbnailMouseDown = (item, event) => {
        let { items, allowDragging } = this.props;

        event.stopPropagation();
        let selection = this.state.selection;
        const isModifierKeyPressed = window.navigator.platform.includes("Mac") ? event.metaKey : event.ctrlKey;
        if (this.props.allowMultiSelect && isModifierKeyPressed) {
            if (selection.contains(item.id)) {
                selection.remove(item.id);
            } else {
                selection.push(item.id);
            }
        } else if (this.props.allowMultiSelect && event.shiftKey && selection.length) {
            let startIndex = this.startSelectionIndex || 0;
            let endIndex = _.findIndex(items, o => o.id == item.id);

            if (endIndex > startIndex) {
                for (let i = startIndex + 1; i <= endIndex; i++) {
                    let item = items[i];
                    if (!selection.contains(item.id)) {
                        selection.push(item.id);
                    }
                }
            } else {
                for (let i = startIndex; i >= endIndex; i--) {
                    let item = items[i];
                    if (!selection.contains(item.id)) {
                        selection.push(item.id);
                    }
                }
            }
        } else {
            if (!selection.contains(item.id)) {
                selection = [item.id];
                this.startSelectionIndex = _.findIndex(items, o => o.id == item.id);
            }
            if (allowDragging) {
                this.dragItems(event, item.id, selection);
            }
        }
        this.setState({ selection: selection });

        this.props.onItemSelected && this.props.onItemSelected(items.filter(item => selection.contains(item.id)));
    }

    handleThumbnailDoubleClick = (item, event) => {
        this.setState({ selection: [item.id] });
        this.props.onDoubleClick && this.props.onDoubleClick(item);
    }

    handleContextMenu = (item, event) => {
        event.preventDefault();
        this.setState({
            showContextMenu: true,
            contextMenuX: event.pageX,
            contextMenuY: event.pageY
        });
    }

    handleCloseContextMenu = () => {
        this.setState({ showContextMenu: false });
    }

    dragItems(event, mousedItemId, selection) {
        let { items } = this.props;

        let startX = event.pageX;
        let startY = event.pageY;

        let $dragSelection;
        let startDrag = () => {
            $dragSelection = $("body").addEl($.div("dragged-items"));
            $dragSelection.css({
                position: "absolute",
                zIndex: 1000,
                width: 133,
                height: 77,
                pointerEvents: "none",
                boxShadow: "10px 10px 20px rgba(0,0,0,.4)"
            });

            for (let itemId of selection) {
                let $el = $(this.gridRef.current).find(`[data-item-id=${itemId}]`);
                if ($el.length) {
                    let $image = $el.find(".thumbnail img").clone();
                    $image.css({ position: "absolute", border: "solid 1px black", pointerEvents: "none" });
                    $image.width(133).left(0).top(0);
                    if (itemId != mousedItemId) {
                        $image.css({ transform: `rotate(${Math.random() * 20 - 10}deg)` });
                        $dragSelection.prepend($image);
                    } else {
                        $dragSelection.append($image);
                    }
                }
            }

            this.props.onStartDragItems && this.props.onStartDragItems(items.filter(item => selection.contains(item.id)));
        };

        $(document).on("mousemove.drag", event => {
            if (!$dragSelection && (Math.abs(event.pageX - startX) > 5 || Math.abs(event.pageY - startY) > 5)) {
                startDrag();
            } else if ($dragSelection) {
                $dragSelection.left(event.pageX).top(event.pageY);
            }
        });

        $(document).on("mouseup.drag", event => {
            $(document).off(".drag");

            if ($dragSelection) {
                $dragSelection.remove();

                _.defer(() => {
                    // defer this so the drop on the folder happens before we turn off isDraggingItems state
                    this.props.onDropDragItems && this.props.onDropDragItems(items.filter(item => selection.contains(item.id)), event);
                });
            }
        });
    }

    render() {
        let { selection, dragBounds, showContextMenu, contextMenuX, contextMenuY } = this.state;
        let { items, thumbnailClass, thumbnailProps = {}, renderThumbnail, onSelected, thumbnailSize = 200, onDoubleClick, readOnly, columns, colGap, rowGap, renderContextMenuItems, gridContainerStyle, showThemeThumbnails = false } = this.props;

        let filteredSelection = items.filter(item => selection.contains(item.id));
        return (
            <Frame style={{ ...gridContainerStyle }} onMouseDown={this.handleGridMouseDown}>
                <StyledThumbnailGrid ref={this.gridRef}
                    columns={columns} colGap={colGap} rowGap={rowGap}
                    className="thumbnail-grid"
                >
                    {
                        items.map((item, idx) => {
                            if (renderThumbnail) {
                                return <Fragment key={idx}>{renderThumbnail(item)}</Fragment>;
                            }
                            return React.createElement(thumbnailClass, {
                                key: `${item.id}:${idx}`,
                                item,
                                onMouseDown: event => this.handleThumbnailMouseDown(item, event),
                                onDoubleClick: event => this.handleThumbnailDoubleClick(item, event),
                                onContextMenu: event => this.handleContextMenu(item, event),
                                selected: selection.contains(item.id),
                                readOnly: readOnly,
                                showThemeThumbnails: showThemeThumbnails,
                                allSelected: items.filter(item => selection.contains(item.id)),
                                renderContextMenuItems: renderContextMenuItems,
                                ...thumbnailProps
                            });
                        })
                    }
                </StyledThumbnailGrid>
                {
                    showContextMenu &&
                    renderContextMenuItems &&
                    <ContextMenu
                        x={contextMenuX}
                        y={contextMenuY}
                        onClose={this.handleCloseContextMenu}
                    >
                        {renderContextMenuItems(filteredSelection)}
                    </ContextMenu>
                }
            </Frame>
        );
    }
}

export const ThumbnailContainer = styled.div.attrs(({ dataId }) => ({
    // Used for identifying the div in cypress tests
    "data-id": dataId
}))`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const ThumbnailDiv = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    background: white;
    align-items: center;
    justify-content: center;
    outline: ${props => props.selected ? `solid 2px ${themeColors.ui_blue}` : "none"};
    transition: all 0.2s;

    ${({ readOnly = false }) => !readOnly && css`
        &:hover{
            box-shadow: 0 0 12px rgba(0,0,0, 0.25) !important;
        }
    `}
    
    &.loading {
        padding-top: 56.25%;
        img {
            position: absolute;
            top: 0px;
        }
    }
    img {
        position: absolute;
        top: 0px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: opacity 600ms;
    }
    .badge {
        position: absolute;
        padding: 4px 8px;
        border-radius: 4px;
        color: white;
        background: orangered;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
        top: -10px;
        &.info {
            background: #11a9e2;
        }
        &.warning {
            background: orangered;
        }
    }
`;

export class Thumbnail extends Component {
    _isMounted = null;

    state = {
        isLoaded: false,
        renderDarkBackground: false,
    }

    constructor() {
        super();
        this.ref = React.createRef();
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadImage();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadImage() {
        const { url } = this.props;
        if (!url || this._isMounted === false) return;
        const image = new Image();
        image.crossOrigin = "Anonymous";
        image.onload = () => {
            const { r, g, b, hasAlpha } = getAverageImageColor(image);
            if (hasAlpha && r > 240 && g > 240 && b > 240) {
                this.setState({
                    isLoaded: true,
                    renderDarkBackground: true
                });
            } else {
                this.setState({
                    isLoaded: true
                });
            }
        };
        image.src = url;
    }

    componentDidUpdate(prevProps, prevState) {
        const { url } = this.props;
        if (url !== prevProps.url) {
            this.setState({ isLoaded: false });
            this.loadImage();
        }
    }

    render() {
        const { url, selected, onMouseDown, onClick, onDoubleClick, style = {}, imageStyle = {}, showSpinner, className, readOnly = false, children } = this.props;
        const { isLoaded, renderDarkBackground } = this.state;

        return (
            <ThumbnailDiv className={`thumbnail ${className ?? ""}`}
                readOnly={readOnly}
                ref={this.ref}
                selected={selected}
                onMouseDown={onMouseDown}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                style={{ ...style, paddingBottom: "56.25%", backgroundColor: renderDarkBackground ? "#333" : "white" }}>
                {url && <img src={url} style={!isLoaded ? { ...imageStyle, opacity: 0 } : { ...imageStyle, opacity: 1 }} />}
                {(!isLoaded && showSpinner) && <Spinner />}
                {children}
            </ThumbnailDiv>
        );
    }
}

export class ThumbnailBadge extends Component {
    render() {
        const { type, children } = this.props;

        return (
            <div className={`badge ${type}`}>
                {children}
            </div>
        );
    }
}

const StyledThumbnail = styled(Thumbnail)`
    border: solid 1px #ccc;

`;

export class SlideThumbnail extends Component {
    state = {
        url: "",
    }

    async componentDidMount() {
        const { slideId, highQuality } = this.props;

        const slide = new Slide({ id: slideId }, { autoSync: false });
        await slide.load();

        const url = await Thumbnails.getSignedUrlAndLoad(slideId, slide.get("modifiedAt"), null, THUMBNAIL_SIZES[highQuality ? "large" : "small"].suffix);
        this.setState({ url });
    }

    render() {
        const { onClick, selected, style } = this.props;
        const { url } = this.state;

        return (
            <ThumbnailContainer className="thumbnail-container" style={style}>
                <StyledThumbnail onClick={onClick} url={url} selected={selected} />
            </ThumbnailContainer>
        );
    }
}

