const theme = {
    isBuiltIn: true,
    id: "horizon",
    name: "Horizon",
    backgroundGradients: [
        {
            angle: 0,
            colorStyle: "dark",
            id: "0f346dc6-d9c2-497b-98ac-3653c1d96164",
            noise: 0,
            stops: [
                {
                    color: "#000000",
                    position: 0
                },
                {
                    color: "#003034",
                    position: 100
                }
            ],
            type: "linear"
        },
        {
            angle: 0,
            colorStyle: "dark",
            id: "7e6541ed-4b75-4b08-871f-e798d4102f7a",
            noise: 0,
            stops: [
                {
                    color: "#000000",
                    position: 0
                },
                {
                    color: "#4D0F30",
                    position: 100
                }
            ],
            type: "linear"
        }
    ],
    cjkFont: "jp",
    colors: {
        accent1: "rgba(1,160,200,1)",
        accent2: "rgba(96,130,230,1)",
        accent3: "rgba(135,108,215,1)",
        accent4: "rgba(226,90,128,1)",
        accent5: "rgba(218,70,68,1)",
        accent6: "rgba(217,92,58,1)",
        accent7: "rgba(217,122,1,1)",
        accent8: "rgba(218,176,59,1)",
        background_accent: "rgba(17,17,17,1)",
        background_dark: "rgba(0,0,0,1)",
        background_light: "#ffffff",
        chart1: "rgba(0,159,162,1)",
        chart2: "rgba(218,176,59,1)",
        chart3: "rgba(1,160,200,1)",
        chart4: "rgba(217,122,1,1)",
        chart5: "rgba(96,130,230,1)",
        chart6: "rgba(217,92,58,1)",
        chart7: "rgba(135,108,215,1)",
        hart8: "rgba(226,90,128,1)",
        chart9: "rgba(218,70,68,1)",
        hyperlink: "rgba(0,159,162,1)",
        egative: "rgba(218,70,68,1)",
        positive: "rgba(0,159,162,1)",
        primary_dark: "rgba(51,51,51,1)",
        primary_light: "rgba(255,255,255,1)",
        secondary_dark: "rgba(0,0,0,0.65)",
        secondary_light: "rgba(255,255,255,0.8)",
        theme: "rgba(0,159,162,1)"
    },
    defaultBackgroundColor: "0f346dc6-d9c2-497b-98ac-3653c1d96164",
    defaultSlideColor: "theme",
    fontBodyFontId: "dmmono",
    fontBodyLetterSpacing: -0.02,
    fontBodyLineHeight: 2,
    fontBodyScaling: 100,
    fontBodyTextTransform: "auto",
    fontBodyWeight: 400,
    fontHeaderFontId: "satoshi",
    fontHeaderLetterSpacing: -0.06,
    fontHeaderLineHeight: 1.6,
    fontHeaderScaling: 100,
    fontHeaderTextTransform: "auto",
    fontHeaderWeight: 900,
    fontScale: 1,
    fontTitleFontId: "satoshi",
    fontTitleLetterSpacing: -0.06,
    fontTitleLineHeight: 1.6,
    fontTitleScaling: 100,
    fontTitleTextTransform: "auto",
    fontTitleWeight: 900,
    footerMessage: `${new Date().getFullYear()} Proprietary and Confidential. All Rights Reserved.`,
    iconStyle: "classic",
    isRTL: false,
    logoOffset: 0,
    logoPosition: "left",
    logoScale: 1,
    palette_name: "custom",
    showFooterByDefault: true,
    showLogo: true,
    showMessage: false,
    showPageNum: true,
    styleBackground: "none",
    styleBodyFont: "sourcesanspro",
    styleDecoration: "none_left",
    styleDesign: 1,
    styleEffect: "none",
    styleElementStyle: "outlined",
    styleFontWeight: "heavy",
    styleShape: "circle",
    styleTitleFont: "bebasneue",
    styleTitleSlide: "bar_left",
    styleWeight: "medium"
};

module.exports = theme;

